import { BucketSize } from '../enums/utility-data'
import dayjs from 'dayjs'

export const capitalize = (value: string) => {
	return value.slice(0, 1).toUpperCase().concat(value.slice(1))
}

export const formatNumber = (value: number) => {
	return Intl.NumberFormat('en-US', {
		style: 'decimal',
		maximumFractionDigits: 3,
		minimumFractionDigits: 3,
	}).format(value)
}

export const formatDate = (date: Date, bucketSize?: BucketSize) => {
	const day = dayjs(date)
	switch (bucketSize) {
		case BucketSize.DAILY:
			return day.format('D MMM')
		case BucketSize.HOURLY:
			return day.format('HH:mm')
		case BucketSize.QUARTER_HOURLY:
			return day.format('HH:mm')
		case BucketSize.MINUTELY:
			return day.format('HH:mm')
		default:
			return day.format('D MMM YYYY, HH:mm:ss')
	}
}