import { Box, CircularProgress, useTheme } from "@mui/material";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { formatNumber } from "../../utils/formatting";
import dayjs from "dayjs";
import { useMemo } from "react";

interface StackedBarChartProps {
  series: ApexAxisChartSeries;
  categories: string[];
  onClick?: (event, chartContext, config) => void;
  showLegend?: boolean;
  title?: string;
  yTitle?: string;
  xTitle?: string;
  yFormatter?: (val: number, opts?: any) => string;
  xFormatter?: (value: string, timestamp?: number, opts?: any) => string;
  tooltipXFormatter?: (val: number, opts?: any) => string;
  colors?: string[];
  id?: string;
  group?: string;
  extraOptions?: ApexOptions;
  fileName?: string;
  xAxisType?: "datetime" | "category" | "numeric";
  isLoading?: boolean;
}

/**
 * Renders data into a stacked bar chart over time.
 */

const StackedBarChart: React.FC<StackedBarChartProps> = ({
  series,
  categories,
  onClick,
  showLegend,
  title,
  xTitle,
  yTitle,
  yFormatter,
  xFormatter,
  tooltipXFormatter,
  colors,
  id,
  group,
  extraOptions,
  fileName,
  xAxisType,
  isLoading,
}) => {
  const theme = useTheme();

  const h3 = theme.typography["h3"];

  const defaultYFormatter = (val: number, opts?: any) => {
    return formatNumber(val);
  };

  const defaultXFormatter = (value: string, timestamp?: number, opts?: any) => {
    if (!!timestamp) {
      return dayjs(timestamp).format("D MMM YYYY, HH:mm");
    }
    return value;
  };

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        id,
        group,
        stacked: true,
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          click: onClick,
        },
        toolbar: {
          autoSelected: "selection",
          export: {
            csv: {
              filename:
                fileName ??
                `${title} ${dayjs(categories[0]).format("DD-MM-YYYY HH:mm:ss")} - ${dayjs(categories[categories.length - 1]).format("DD-MM-YYYY HH:mm:ss")}`,
              headerCategory: "Timestamp",
              dateFormatter: (value: any) =>
                dayjs(value).format("DD-MM-YYYY HH:mm:ss"),
            },
          },
        },
      },
      title: {
        text: title ?? "",
        style: {
          fontFamily: theme.typography.fontFamily,
          fontSize: h3.fontSize.toString() ?? "18",
          fontWeight: h3.fontWeight,
        },
      },
      legend: {
        show: showLegend,
        position: "top",
        fontFamily: theme.typography.fontFamily,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
        },
      },
      xaxis: {
        type: xAxisType,
        categories: categories,
        labels: {
          formatter:
            xAxisType === "datetime"
              ? xFormatter ?? defaultXFormatter
              : undefined,
          style: {
            colors: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily,
          },
        },
        title: {
          text: xTitle ?? "",
          offsetY: -25,
        },
        tooltip: {
          formatter: (value) => dayjs(value).format("D MMM YYYY, HH:mm"),
        },
      },
      yaxis: {
        tickAmount: 3,
        labels: {
          formatter: yFormatter ?? defaultYFormatter,
          style: {
            colors: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily,
          },
        },
        title: {
          text: yTitle ?? "",
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: theme.palette.divider,
      },
      tooltip: {
        shared: true,
        intersect: false,
        theme: theme.palette.mode,
        style: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize.toString(),
        },
        y: {
          formatter: yFormatter ?? defaultYFormatter,
        },
        x: {
          formatter: tooltipXFormatter,
        },
      },
      colors,
      ...extraOptions,
    }),
    [series]
  );

  return isLoading ? (
    <Box
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Chart
      key={JSON.stringify(series)}
      type="bar"
      options={options}
      series={series}
      height={"100%"}
    />
  );
};

export default StackedBarChart;
